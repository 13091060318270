import { createBooleanFeatureFlag, createFeatureFlagConfig, createRemoteFeatureFlag } from './featureFlags.factories';

export const FEATURE_FLAG_VALUES = {
  layout: createFeatureFlagConfig('layout', ['aloha', 'brannan']),
  guestsitetarget: createFeatureFlagConfig('guestsitetarget', ['bliss', 'charm']),
  nossr: createBooleanFeatureFlag('nossr'),
  joyWeb: createFeatureFlagConfig('joy-web', true),
  pseudoLocalization: createFeatureFlagConfig('pseudoloc', ['true', 'all']),
  addCashGift: createBooleanFeatureFlag('addcashgift'),
  registryGiftTracking: createBooleanFeatureFlag('registryGiftTracking'),
  bookmarkletPopup: createBooleanFeatureFlag('bookmarkletPopup'),
  productPromoFeed: createBooleanFeatureFlag('productPromoFeed'),
  externalRegistry: createBooleanFeatureFlag('externalRegistry'),
  enableRegistryPasswordUnlock: createBooleanFeatureFlag('enableRegistryPasswordUnlock'),
  disableRegistryPhotoUpload: createBooleanFeatureFlag('disableregistryphotoupload'),
  enableSsr: createBooleanFeatureFlag('enablessr'),
  enableCurationHero: createBooleanFeatureFlag('enableCurationHero'),
  enableCurationHeroVideo: createBooleanFeatureFlag('enableCurationHeroVideo'),
  giftReservation: createFeatureFlagConfig('giftReservation', ['true', 'r3', 'r4']),
  compactBuildPage: createBooleanFeatureFlag('compactBuildPage'),
  enableProductColorSwatch: createBooleanFeatureFlag('enableProductColorSwatch'),
  enableCountryDropdown: createBooleanFeatureFlag('enableCountryDropdown'),
  tileColorSwatchInteraction: createFeatureFlagConfig('tileColorSwatchInteraction', ['hover', 'click', 'hover-n-click']),
  doRegistryOnboardingCardsReset: createBooleanFeatureFlag('doRegistryOnboardingCardsReset'),
  enableNewCurationGrid: createBooleanFeatureFlag('enableNewCurationGrid'),
  enableRegistryOnboardingSurveyDialog: createBooleanFeatureFlag('enableRegistryOnboardingSurveyDialog'),
  registryOnboardingSurvey: createFeatureFlagConfig('registryOnboardingSurvey', ['force', 'reset', 'force5']),
  enableFullstoryTracking: createBooleanFeatureFlag('enableFullstoryTracking'),
  enableRegistryTransfer: createBooleanFeatureFlag('enableRegistryTransfer', true),
  enableCreateWeddingProgressBar: createBooleanFeatureFlag('enableCreateWeddingProgressbar'),
  enableClearCartFeature: createBooleanFeatureFlag('enableClearCartFeature'),
  enableHaveYouPurchasedModal: createBooleanFeatureFlag('enableHaveYouPurchasedModal', true),
  enableHaveYouPurchasedModalExternalLinkClick: createBooleanFeatureFlag('enableHaveYouPurchasedModalExternalLinkClick', true),
  enableCashFundCheckoutV2: createBooleanFeatureFlag('enableCashFundCheckoutV2', true),
  enableLoadingStyleApplicator: createBooleanFeatureFlag('enableLoadingStyleApplicator'),
  enableAddressVerificationModal: createBooleanFeatureFlag('enableAddressVerificationModal'),
  enableQuickAddLandingPage: createBooleanFeatureFlag('enableQuickAddLandingPage'),
  enableAddEditGiftNote: createBooleanFeatureFlag('enableAddEditGiftNote', true),
  enableCurationHomeExpert: createBooleanFeatureFlag('enableCurationHomeExpert', true),
  enableBuyForMyselfFromPurchaseConfirmation: createBooleanFeatureFlag('enableBuyForMyselfFromPurchaseConfirmation', true),
  enableRandomLabelsGuestRegistryProducts: createBooleanFeatureFlag('enableRandomLabelsGuestRegistryProducts'),
  enableSplitPurchaseDialog: createBooleanFeatureFlag('enableSplitPurchaseDialog', true),
  enableShippingAddressWarning: createBooleanFeatureFlag('enableShippingAddressWarning', true),
  orderTrackingFeature: createBooleanFeatureFlag('orderTrackingFeature', true),
  enableMFASettings: createBooleanFeatureFlag('enableMFASettings', true),
  enableCurationsSection: createBooleanFeatureFlag('enableCurationsSection', true),
  enableAffiliateCheckoutV2: createBooleanFeatureFlag('enableAffiliateCheckoutV2', true),
  enableNewExternalRegistry: createBooleanFeatureFlag('enableNewExternalRegistry'),
  enableChecklistInGiftBasketWidget: createBooleanFeatureFlag('enableChecklistInGiftBasketWidget', true),
  enableBlankDates: createBooleanFeatureFlag('enableBlankDates', true),
  enableCustomEventName: createBooleanFeatureFlag('enableCustomEventName', true),
  enableExplodedCategoryV2: createBooleanFeatureFlag('enableExplodedCategoryV2', true),
  enableBabyRegistry: createBooleanFeatureFlag('enableBabyRegistry', true),
  enableNewNavMenu: createBooleanFeatureFlag('enableNewNavMenu', true),
  guestSiteSafeLinks: createBooleanFeatureFlag('guestSiteSafeLinks', true),
  enableExternalRegistriesForBabyReg: createBooleanFeatureFlag('enableExternalRegistriesForBabyReg', true),
  enableBabyRegistrySoftLaunchFeatures: createBooleanFeatureFlag('enableBabyRegistrySoftLaunchFeatures', true),
  babyRegistrySignupEnabled: createRemoteFeatureFlag('babyregistrysignupenabled'),
  seamlessCheckoutVariant: createRemoteFeatureFlag({ key: 'wgseamlesscheckout', supportedValues: ['variant1', 'variant2', 'variant3'] }),
  hideSyncInstead: createRemoteFeatureFlag({ key: 'hidesyncinstead' }),
  manualItemAutocompleteEnabled: createRemoteFeatureFlag({ key: 'manualitemautocompleteenabled', supportedValues: ['on', 'off'] }),
  shopProductConfirmationToastV2: createRemoteFeatureFlag('shoproductconfirmationtoastv2'),
  joyRefundsEnabled: createRemoteFeatureFlag({ key: 'joyrefundsenabled', supportedValues: ['on', 'off'] }),
  dashboardFeedbackSurveyEnabled: createRemoteFeatureFlag('dashboardFeedbackSurveyEnabled'),
  guestLinkUseRedirectService: createRemoteFeatureFlag({ key: 'guestlinkuseredirectservice', supportedValues: ['on', 'off'] }),
  enableInlineEditing: createBooleanFeatureFlag('enableInlineEditing', false),
  isMobileInlineEditMode: createBooleanFeatureFlag('isMobileInlineEditMode', false),
  supportAutocompletePreDefinedPlacesEnabled: createRemoteFeatureFlag({ key: 'supportAutocompletePreDefinedPlacesEnabled', supportedValues: ['on', 'off'] }),

  /**
   * Is being AB tested
   */
  registryMergeAddGiftAndOverviewPagesEnabled: createRemoteFeatureFlag({ key: 'registryMergeAddGiftAndOverviewPagesEnabled', supportedValues: ['control', 'treatment'] }),
  enableShopNavInManage: createBooleanFeatureFlag('enableShopNavInManage'),
  asyncApolloAuthEnabled: createRemoteFeatureFlag('asyncApolloAuthEnabled'),
  registryAdminShoppingEnabled: createRemoteFeatureFlag('registryAdminShoppingEnabled'),
  cambiumGroupRegistryReferralsEnabled: createRemoteFeatureFlag('cambiumGroupRegistryReferralsEnabled'),
  universalCatalogSearchEnabled: createRemoteFeatureFlag('universalCatalogSearchEnabled'),
  universalCatalogMeshExperiment: createRemoteFeatureFlag({
    key: 'universalCatalogMeshExperiment',
    supportedValues: ['control', 'low-volume-google-shopping-first', 'low-volume-joy-catalog-first', 'joy-catalog-first']
  }),
  registryCatalogFiltersOpenDefaultEnabled: createRemoteFeatureFlag({ key: 'registryCatalogFiltersOpenDefaultEnabled', supportedValues: ['control', 'treatment'] }),
  registryTrendingSearchEnabled: createRemoteFeatureFlag({ key: 'registryTrendingSearchEnabled', supportedValues: ['control', 'treatment'] }),
  registryPromoteSeamlessCheckoutEnabled: createRemoteFeatureFlag({ key: 'registryPromoteSeamlessCheckoutEnabled', supportedValues: ['control', 'treatment'] }),
  /**
   * Prevent using cached SSR html.
   */
  skipSsrCache: createBooleanFeatureFlag('skipssrcache'),
  logSsrUserAgent: createBooleanFeatureFlag('logSsrUserAgent'),

  exampleOnOffRemoteFeatureFlag: createRemoteFeatureFlag('exampleOnOffFeatureFlag'),
  exampleMultiVariantRemoteFeatureFlag: createRemoteFeatureFlag({ key: 'examplemultivariantfeatureflag', supportedValues: ['variant1', 'variant2'], defaultValue: 'variant1' }),
  exampleExperimentFeatureFlag: createRemoteFeatureFlag({ key: 'exampleexperimentfeatureflag', supportedValues: ['control', 'treatment'] }),
  exampleExperimentBucketedByJoyEvent: createRemoteFeatureFlag({ key: 'exampleexperimentbucketedbyjoyevent', supportedValues: ['control', 'treatment'] }),
  adminDashboardOverviewFAQs: createRemoteFeatureFlag({ key: 'adminDashboardOverviewFAQs', supportedValues: ['control', 'treatment'] }),
  adminDashboardOverviewHighlights: createRemoteFeatureFlag({ key: 'adminDashboardOverviewHighlights', supportedValues: ['control', 'variant1', 'variant2'] }),
  /*
   * Gift Wrap Remote Feature Flags
   */
  registryGiftWrapUseEventAddress: createRemoteFeatureFlag('registrygiftwrapuseeventaddress'),
  registryDropshipGiftWrapFlowVariant: createRemoteFeatureFlag({
    key: 'registrydropshipgiftwrapflowvariant',
    supportedValues: ['original', 'shopping-cart'],
    defaultValue: 'original'
  }),
  registryGiftWrapDefaultUSABillingAddress: createRemoteFeatureFlag('registrygiftwrapdefaultusabillingaddress'),
  registryGiftWrapEcardPrice: createRemoteFeatureFlag('registrygiftwrapecardprice'),
  registryGiftWrapCartEnabled: createRemoteFeatureFlag('registrygiftwrapCartenabled'),
  registryGiftWrapDropshipEnabled: createRemoteFeatureFlag('registrygiftwrapdropshipenabled'),
  registryGiftWrapExperimentEnabled: createRemoteFeatureFlag('registrygiftwrapexperimentenabled'),
  registryGiftWrapCashEnabled: createRemoteFeatureFlag('registrygiftwrapcashenabled'),
  registryGiftWrapAffiliateEnabled: createRemoteFeatureFlag('registrygiftwrapaffiliateenabled'),
  registryGiftWrapRandomizeThemeOrder: createRemoteFeatureFlag('registrygiftwraprandomizethemeorder'),
  registryGiftWrapEnableECardDelivery: createRemoteFeatureFlag('registrygiftwrapenableecarddelivery'),
  registryGiftWrapPremiumEnabled: createRemoteFeatureFlag('registrygiftwrappremiumenabled'),
  registryGiftWrapThemeDisplayCount: createRemoteFeatureFlag('registrygiftwrapthemedisplaycount'),
  registryGiftWrapThemeExpandIcon: createRemoteFeatureFlag({
    key: 'registrygiftwrapthemeexpandicon',
    supportedValues: ['plus', 'chevron'],
    defaultValue: 'plus'
  }),
  registryGiftWrapUseEnvelopeInEditor: createRemoteFeatureFlag('registrygiftwrapuseenvelopeineditor'),
  registryGiftWrapUseEnvelopeForRecipient: createRemoteFeatureFlag('registrygiftwrapuseenvelopeforrecipient'),
  rsvpCongratulationsEcardUserIsInUS: createRemoteFeatureFlag('rsvpCongratulationsEcardUserIsInUS'),
  rsvpCongratulationsEcardExperimentEnabled: createRemoteFeatureFlag('rsvpCongratulationsEcardExperimentEnabled'),
  rsvpCongratulationsECardPrice: createRemoteFeatureFlag('rsvpCongratulationsECardPrice'),
  rsvpCongratulationsEventHandleExcludeList: createRemoteFeatureFlag('rsvpcongratulationseventhandleexcludelist'),
  rsvpVersion: createRemoteFeatureFlag({ key: 'rsvpVersion', supportedValues: ['basic', 'offer'] }),
  /*
   * Print Remote Feature Flags
   */
  greetingCardsEnabled: createRemoteFeatureFlag('greetingcardsenabled'),
  eCardEnableShareableLink: createRemoteFeatureFlag('ecardenableshareablelink'),
  enableNewPrintExperience: createRemoteFeatureFlag({ key: 'enablenewprintexperience', supportedValues: ['on', 'off'] }),
  printAllowAlaskaHawaiiShipping: createRemoteFeatureFlag('printallowalaskahawaiishipping'),
  printCouponCodeLaunch: createRemoteFeatureFlag({ key: 'printcouponcodelaunch', supportedValues: ['on'] }),
  printHomePromotionBanner: createRemoteFeatureFlag({ key: 'printhomepromotionbanner', supportedValues: ['on', 'custom'] }),
  printDashboardEarlyAccessDialogV2: createRemoteFeatureFlag({ key: 'printdashboardearlyaccessdialogv2', supportedValues: ['control', 'treatment'] }),
  printPaperlustConciergeServiceCarousel: createRemoteFeatureFlag({ key: 'printpaperlustconciergeservicecarousel', supportedValues: ['on', 'gallery'] }),
  printPaperlustShowConciergeServiceInGallery: createRemoteFeatureFlag('printpaperlustshowconciergeserviceingallery'),
  printCreateWeddingRedirect: createRemoteFeatureFlag({ key: 'printcreateweddingredirect', supportedValues: ['control', 'treatment'] }),
  printAdminDashboardIconUpdate: createRemoteFeatureFlag({ key: 'printadmindashboardiconupdate', supportedValues: ['control', 'treatment', 'off'] }),
  printAdminDashboardTargetPrintDialogDelay: createRemoteFeatureFlag({ key: 'printadmindashboardtargetprintdialogdelay', supportedValues: ['delayLengthInHours'] }),
  giftWrapOptionsOrderExperiment: createRemoteFeatureFlag({ key: 'giftwrapoptionsorderexperiment', supportedValues: ['control', 'treatment'] }),
  printDesignConciergeCheckoutEnabled: createRemoteFeatureFlag('printdesignconciergecheckoutenabled'),
  printSuperAdminDiscountEnabled: createRemoteFeatureFlag('printsuperadmindiscountenabled'),
  printGalleryThemeDisplayOrder: createRemoteFeatureFlag({ key: 'printgallerythemedisplayorder', supportedValues: ['on', 'off'] }),
  printGalleryGridStyle: createRemoteFeatureFlag({ key: 'printgallerygridstyle', supportedValues: ['control', 'treatment'] }),
  printWebsiteDesignerMatchingBanner: createRemoteFeatureFlag({ key: 'websitedesignermatchingpaperbanner', supportedValues: ['control', 'treatment'] }),
  printWebsiteDesignerMatchingModal: createRemoteFeatureFlag({ key: 'websitedesignerprintcrosssellmodal', supportedValues: ['control', 'treatment'] }),
  printDigitalInternationalShipping: createRemoteFeatureFlag({ key: 'printdigitalinternationalshipping', supportedValues: ['restricted', 'off'] }),
  printInternationalShipping: createRemoteFeatureFlag({ key: 'printinternationalshipping', supportedValues: ['restricted', 'off'] }),
  printEnableDigitalEditor: createRemoteFeatureFlag('printenabledigitaleditor'),
  printEnableDraftsV2: createRemoteFeatureFlag('printenabledraftsv2'),
  printPublicShopEnabled: createRemoteFeatureFlag('printpublicshopenabled'),
  printImagineOptions: createRemoteFeatureFlag('printimagineoptions'),
  printImagineHoliday: createRemoteFeatureFlag('printimagineholiday'),
  printImagineGalleryEnabled: createRemoteFeatureFlag('printimaginegalleryenabled'),
  printImagineShowValueProps: createRemoteFeatureFlag('printimagineshowvalueprops'),
  printImagineStyles: createRemoteFeatureFlag('printimaginestyles'),
  printHolidayCategoryEnabled: createRemoteFeatureFlag('printholidaycategoryenabled'),
  printStDPaperlustConciergeEnabled: createRemoteFeatureFlag('printstdpaperlustconciergeenabled'),
  printEnableSampleOrder: createRemoteFeatureFlag('printenablesampleorder'),
  printValuePropsData: createRemoteFeatureFlag({ key: 'printvaluepropsdata', supportedValues: ['on', 'group1', 'group2'] }),
  printSaleCodeV2: createRemoteFeatureFlag({ key: 'printsalecodev2', supportedValues: ['group1', 'group2', 'group3'] }),
  printGalleryVideoAutoPlay: createRemoteFeatureFlag('printgalleryvideoautoplay'),
  printCouponCodeLaunchV2: createRemoteFeatureFlag('printcouponcodelaunchv2'),
  printPremiumDigitalPricing: createRemoteFeatureFlag('printpremiumdigitalpricing'),
  printDigitalCustomizerReviewStepPrevievExperiment: createRemoteFeatureFlag({
    key: 'printdigitalcustomizerreviewstepprevievexperiment',
    supportedValues: ['control', 'treatment']
  }),
  printPdpPhotoGalleryLayoutExperiment: createRemoteFeatureFlag({ key: 'printpdpphotogallerylayoutexperiment', supportedValues: ['control', 'treatment'] }),
  printFavoritesRouteEnabled: createRemoteFeatureFlag('printfavoritesrouteenabled'),
  printPopularDesignPdpVideoExperiment: createRemoteFeatureFlag({ key: 'printpopulardesignpdpvideosexperiment', supportedValues: ['control', 'treatment'] }),
  printPdpShippingDateEstimationExperiment: createRemoteFeatureFlag({
    key: 'printpdpshippingdateestimationexperiment',
    supportedValues: ['control', 'treatment']
  }),
  printRegistryThankYouCrossSellExperiment: createRemoteFeatureFlag({ key: 'printregistrythankyoucrosssellexperiment', supportedValues: ['control', 'treatment'] }),
  printDesignGalleryTileAssetRotationOnHoverExperiment: createRemoteFeatureFlag({
    key: 'printdesigngallerytileassetrotationonhoverexperiment',
    supportedValues: ['control', 'treatment']
  }),
  printPremiumOrderTrackingEnabled: createRemoteFeatureFlag({ key: 'printpremiumordertrackingenabled', supportedValues: ['on', 'off'] }),
  printGalleryEnableSearch: createRemoteFeatureFlag('printgalleryenablesearch'),
  printDigitalSMSUpsellEnabled: createRemoteFeatureFlag({ key: 'printdigitalsmsupsellenabled', supportedValues: ['on', 'off'] }),
  printSalesAllowedCountryList: createRemoteFeatureFlag({ key: 'printsalesallowedcountrylist', supportedValues: ['on', 'off'] }),
  printStripeTaxCodeList: createRemoteFeatureFlag({ key: 'printstripetaxcodelist', supportedValues: ['on', 'off'] }),
  printPdpValuePropPlacementExperiment: createRemoteFeatureFlag({ key: 'printpdpvaluepropplacementexperiment', supportedValues: ['control', 'treatment'] }),
  printEnclosureCardEnabled: createRemoteFeatureFlag('printenclosurecategoryenabled'),
  printGalleryCardTileCarouselContent: createRemoteFeatureFlag('printgallerycardtilecarouselcontent'),
  printPdpPreviewPaneAssetsData: createRemoteFeatureFlag('printpdppreviewpaneassetsdata'),
  printGalleryShowFreeDigitalPricing: createRemoteFeatureFlag('printgalleryshowfreedigitalpricing'),
  printPdpEnableInteractiveDigitalPreview: createRemoteFeatureFlag('printpdpenableinteractivedigitalpreview'),
  printPdpEnableInteractivePrintPreview: createRemoteFeatureFlag('printpdpenableinteractiveprintpreview'),
  printRecipientFlowVariant: createRemoteFeatureFlag({ key: 'printrecipientflowvariants', supportedValues: ['control', 'treatment'] }),
  printCsvUploadEnabled: createRemoteFeatureFlag('printcsvuploadenabled'),
  /*
   * Website Designer Remote Feature Flags
   */
  websiteDesignerTemplateReorder: createRemoteFeatureFlag({ key: 'websitedesignertemplatereorderexperiment', supportedValues: ['control', 'treatment'] }),
  /*
   * Thank You Manager Remote Feature Flags
   */
  registryGiftTrackerThankYouManager: createRemoteFeatureFlag('registrygifttrackerthankyoumanager'),
  /*
   * Registry Admin Remote Feature Flags
   */
  fullstoryRecordRegistryAdminEnabled: createRemoteFeatureFlag('fullstoryrecordregistryadminenabled'),
  enableAddFromFriendsRegistry: createRemoteFeatureFlag('enableAddFromFriendsRegistry'),
  registryOverviewVirtualConsultation: createRemoteFeatureFlag({ key: 'registryOverview-VirtualConsultation', supportedValues: ['control', 'treatment'] }),
  registryDelayShippingEnabled: createRemoteFeatureFlag('registryDelayShippingEnabled'),
  catalogBrandsDirectoryEnabled: createRemoteFeatureFlag('catalogBrandsDirectoryEnabled'),
  adminRegistryGetCashEquivalentEnabled: createRemoteFeatureFlag('adminRegistryGetCashEquivalentEnabled'),
  registryFlatShippingFeeEnabled: createRemoteFeatureFlag('registryFlatShippingFeeEnabled'),
  remindMeLaterSmsRegistryAcquisitionExperiment: createRemoteFeatureFlag({ key: 'remindmelatersmsregistryacquisitionexperiment', supportedValues: ['control', 'treatment'] }),
  registryDashboardTitlesExperiment: createRemoteFeatureFlag({ key: 'registryDashboardTitlesExperiment', supportedValues: ['control', 'treatment'] }),
  messagingEnableHotelBlockReminder: createRemoteFeatureFlag('messagingenablehotelblockreminder'),
  messagingEmailDomainUnavailableNotification: createRemoteFeatureFlag('messagingemaildomainunavailablenotification'),
  socialproofsinglepageonboarding: createRemoteFeatureFlag({ key: 'socialproofsinglepageonboarding', supportedValues: ['control', 'treatment'] }),
  adminRegistryEnforceShippingAddressEnabled: createRemoteFeatureFlag({
    key: 'adminRegistryEnforceShippingAddressEnabled',
    supportedValues: ['control', 'treatment', 'alternativeclose']
  }),
  adminDashboardShippingAddressReminderEnabled: createRemoteFeatureFlag({ key: 'adminDashboardShippingAddressReminderEnabled' }),
  //old universal recommendation flag - buggy flag - we keep it here to rise an issue to amplitude
  syncAndTransferRegistryProductRecommendation: createRemoteFeatureFlag({
    key: 'syncAndTransferRegistryProductRecommendation',
    supportedValues: ['control', 'treatmentskipextrastepsintransfersync', 'treatmentfullrecommendationflow']
  }),
  // new fresh feature flag, replaces syncAndTransferRegistryProductRecommendation
  registryUniversalRecommendationsEnabled: createRemoteFeatureFlag({ key: 'registryUniversalRecommendationsEnabled' }),
  paidAdsForShopEnabled: createRemoteFeatureFlag('paidadsforshopenabled'),
  selectFavoriteRetailer: createRemoteFeatureFlag({ key: 'selectfavoriteretailer', supportedValues: ['control', 'on'] }),
  registryDisableSyncRegistry: createRemoteFeatureFlag({ key: 'registryDisableSyncRegistry', supportedValues: ['control', 'treatment'] }),
  registrySyncIsDisabledDate: createRemoteFeatureFlag({ key: 'registrySyncIsDisabledDate' }),
  /*
   * Travel Remote Feature Flags
   */
  adminDashboardRetargetTravelDialogDelay: createRemoteFeatureFlag({ key: 'admindashboardretargettraveldialogdelay', supportedValues: ['delayLengthInHours'] }),
  hotelBlockEnabled: createRemoteFeatureFlag('hotelblockenabled'),
  shortlistReleased: createRemoteFeatureFlag('shortlistreleased'),
  hotelBlockContactPreferenceEnabled: createRemoteFeatureFlag({ key: 'hotelblockcontactpreferenceenabled', supportedValues: ['on', 'off'] }),
  hotelBlockContactMethodMeeting: createRemoteFeatureFlag({ key: 'hotelBlockContactMethodMeeting', supportedValues: ['off', 'on'] }),
  hotelBlockContactMethodSms: createRemoteFeatureFlag({ key: 'hotelBlockContactMethodSms', supportedValues: ['off', 'on'] }),
  hotelBlockContactMethodEmail: createRemoteFeatureFlag({ key: 'hotelBlockContactMethodEmail', supportedValues: ['off', 'on'] }),
  remindMeLaterDialogueExperiment: createRemoteFeatureFlag({ key: 'remindmelaterdialogueexperiment', supportedValues: ['control', 'treatment'] }),
  newtimepickerEnabled: createRemoteFeatureFlag({ key: 'newtimepicker', supportedValues: ['on', 'off'] }),
  hotelBlockAccommodations: createRemoteFeatureFlag({ key: 'hotelblockaccommodations', supportedValues: ['on', 'off'] }),
  adminEditAccommodationsEnabled: createRemoteFeatureFlag({ key: 'admineditaccommodationsenabled', supportedValues: ['on', 'off'] }),
  perkIntercomWorkflowTest: createRemoteFeatureFlag({ key: 'perkIntercomWorkflowTest', supportedValues: ['on', 'off'] }),
  roomBlockManagerDialogPerkPrintPromoCode: createRemoteFeatureFlag({ key: 'roomblockmanagerdialogperkprintpromocode', supportedValues: ['on', 'off'] }),
  roomBlockManagerDialogRegistryPromoCode: createRemoteFeatureFlag({ key: 'roomblockmanagerdialogregistrypromocode', supportedValues: ['on', 'off'] }),
  smsOnlyLeadGenerationExperiment: createRemoteFeatureFlag({ key: 'smsonlyleadgenerationexperiment', supportedValues: ['control', 'treatment'] }),
  accommodationsGuestV2Enabled: createRemoteFeatureFlag({ key: 'accommodationsGuestV2Enabled', supportedValues: ['on', 'off'] }),
  accommodationsPostRsvpExperiment: createRemoteFeatureFlag({ key: 'accommodationsPostRSVPExperiment', supportedValues: ['control', 'treatment'] }),
  accommodationsPostRsvpDesignUpdateExperiment: createRemoteFeatureFlag({ key: 'accommodationspostrsvpdesignupdateexperiment', supportedValues: ['control', 'treatment'] }),
  planningAccommodationsExperiment: createRemoteFeatureFlag({ key: 'planningAccommodationsExperiment', supportedValues: ['control', 'treatment'] }),
  accommodationsGuestSafeLinkEnabled: createRemoteFeatureFlag({ key: 'accommodationsGuestSafeLinkEnabled', supportedValues: ['on', 'off'] }),
  accommodationsAutoPopulatePlacesExperiment: createRemoteFeatureFlag({ key: 'accommodationsAutoPopulatePlacesExperiment', supportedValues: ['control', 'treatment'] }),
  accommodationsFirstEnabled: createRemoteFeatureFlag({ key: 'accommodationsFirstEnabled', supportedValues: ['on', 'off'] }),
  accommodationsGuestButtonExperiment: createRemoteFeatureFlag({ key: 'accommodationsGuestButtonExperiment', supportedValues: ['control', 'treatment'] }),
  accommodationsGuestHotelCheckoutEnabled: createRemoteFeatureFlag({ key: 'accommodationsGuestHotelCheckoutEnabled', supportedValues: ['on', 'off'] }),

  /**
   * ZentrumHub
   */
  zentrumHubEnabled: createRemoteFeatureFlag({ key: 'zentrumHubEnabled', supportedValues: ['on', 'off'] }),

  /**
   * https://app.amplitude.com/experiment/joylife/142907/config/310745/configure
   */
  zentrumHubNearbyHotelsEnabled: createRemoteFeatureFlag({ key: 'zentrumHubNearbyHotelsEnabled', supportedValues: ['on', 'off'] }),

  /**
   * https://app.amplitude.com/experiment/joylife/142907/config/310744/configure
   */
  zentrumHubPartnerEnabled: createRemoteFeatureFlag({ key: 'zentrumHubPartnerEnabled', supportedValues: ['on', 'off'] }),

  /**
   * https://app.amplitude.com/experiment/joylife/142907/config/310747/configure
   */
  zentrumHubBookingAssistantEnabled: createRemoteFeatureFlag({ key: 'zentrumHubBookingAssistantEnabled', supportedValues: ['on', 'off'] }),

  /**
   *
   */
  discountedRatesMessageApplied: createRemoteFeatureFlag({ key: 'discountedRatesMessageApplied', supportedValues: ['on', 'off'] }),

  /*
   * Contact Collection Feature Flags
   */
  contactCollectionEnabled: createRemoteFeatureFlag('contactCollectionEnabled'),
  contactCollectionInternationalAddressValidationEnabled: createRemoteFeatureFlag('contactCollectionInternationalAddressValidationEnabled'),
  contactCollectionMessagesTermsAndConditionsEnabled: createRemoteFeatureFlag('contactCollectionMessagesTermsAndConditionsEnabled'),
  contactCollectionSmsAgreementVariant: createRemoteFeatureFlag({ key: 'contactCollectionSmsAgreementVariant', supportedValues: ['variant1', 'variant2'] }),
  contactCollectionMobileCallbacksEnabled: createRemoteFeatureFlag('contactCollectionMobileCallbacksEnabled'),
  contactCollectionDisablePhoneNumberValidationFor: createRemoteFeatureFlag('contactcollectiondisablephonenumbervalidationfor'),
  contactCollectionCsvUploadEnabled: createRemoteFeatureFlag('contactcollectioncsvuploadenabled'),
  shopSubCatHighlightsExperiment: createRemoteFeatureFlag({ key: 'shopSubCatHighlightsExperiment', supportedValues: ['control', 'treatment'] }),
  eventDashboardDefaultTabExperiment: createRemoteFeatureFlag({ key: 'eventDashboardDefaultTabExperiment', supportedValues: ['control', 'treatment'] }),
  adminDashboardMultiProductPromoDialog: createRemoteFeatureFlag({ key: 'admindashboardmultiproductpromodialog', supportedValues: ['control', 'treatment', 'off'] }),
  AdminDashboardChecklist: createRemoteFeatureFlag({ key: 'AdminDashboardChecklist', supportedValues: ['control', 'treatment'] }),
  adminDashboardMultipleProductPromoDialogCreationDateCutoff: createRemoteFeatureFlag({
    key: 'admindashboardmultiproductpromodialogcreationdatecutoff',
    supportedValues: ['on', 'off']
  }),

  weddingShopFeatureEnabled: createRemoteFeatureFlag({ key: 'weddingshopfeatureenabled', supportedValues: ['on', 'off', 'variant2'] }),
  websiteBrannanFullBleedImageEnabled: createRemoteFeatureFlag({ key: 'websitebrannanfullbleedimageenabled', supportedValues: ['on', 'off'] }),

  /**
   * Create Wedding
   */
  datepickerAutocloseExperiment: createRemoteFeatureFlag({ key: 'datepickerAutocloseExperiment', supportedValues: ['control', 'treatment'] }),

  /**
   * Admin Dashboard
   */
  fontPackExperiment: createRemoteFeatureFlag({ key: 'fontpackexperiment', supportedValues: ['control', 'treatment'] }),
  photoAlbumMultiPhotoUploadEnabled: createRemoteFeatureFlag({ key: 'photoalbummultiphotoupload', supportedValues: ['on', 'off'] }),
  allCollectionPhotosEnabled: createRemoteFeatureFlag({ key: 'allcollectionphotosenabled', supportedValues: ['on', 'off'] }),

  /**
   * Guest site
   */
  guestBrannanCoverPhotoV2Enabled: createRemoteFeatureFlag({ key: 'guestbrannancoverphotov2enabled', supportedValues: ['on', 'off'] }),
  alohaMobilePagePhotoEnabled: createRemoteFeatureFlag({ key: 'alohamobilepagephoto', supportedValues: ['on', 'off'] }),
  /**
   * Webiste Design
   */
  joyWebAppBoundaryEnabled: createRemoteFeatureFlag({ key: 'joywebappboundaryenabled', supportedValues: ['on', 'off'] }),

  /**
   * Admin dashboard V3
   */
  adminDashboardV3StartDate: createRemoteFeatureFlag({ key: 'adminDashboardV3StartDate', supportedValues: ['control', 'treatment'] }),
  adminDashboardV3: createRemoteFeatureFlag({ key: 'adminDashboardV3', supportedValues: ['control', 'treatment'] }),
  adminDashboardV3Popups: createRemoteFeatureFlag({ key: 'adminDashboardV3Popups', supportedValues: ['control', 'treatment'] }),
  adminDashboardV3LeftColPromoCards: createRemoteFeatureFlag({ key: 'adminDashboardV3LeftColPromoCards', supportedValues: ['control', 'treatment'] }),
  planningWhiteLabelPropertyExperiment: createRemoteFeatureFlag({ key: 'planningWhiteLabelPropertyExperiment', supportedValues: ['control', 'treatment'] }),
  hotelFirstPartySearchEnabled: createRemoteFeatureFlag({ key: 'hotelfirstpartysearchenabled', supportedValues: ['control', 'treatment'] })
};

export type FeatureFlags = typeof FEATURE_FLAG_VALUES;
